body,
html,
#root,
.App {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}


@font-face {
  font-family: Inkburrow;
  src: url(fonts/Inkburrow.ttf);
}

.feildImage {
  position: relative;
  height: 100%;
  z-index: 10;
}


.half-bone {
  position: relative;
  z-index: 20;
  height: 95%;
}

.marrow{
  position: absolute;
  z-index: 20;
  height: 100%;
  width:100%;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hexo {
  position: relative;

  height: 100%;

}

.half-hold {

  position: relative;
  height: 50%;
  width: fit-content;
}




.bonecase {
  position: absolute;
  height: 14%;
  z-index: 20;
  /* margin: 0 3.5%; */
  top: 0;
  left: 0
}

.bone {
  position: relative;
  height: 100%;
  /* height: 25%;
  z-index: 20;
  margin: 0 4%;  */
  transition: transfrom 1s;
}


.boneBox {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 35%;
  z-index: 20;
  border: 1px solid blue;
  top: 0;
  left: 0
}

.rotates {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: -50%;
  left: 0;
  height: 200%;
  width: 100%;
  z-index: 50;
}

.ro-control {
  position: relative;
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}


.ro-control img {
  position: relative;
  height: 100%;
  margin: 0;
}


* ::-webkit-scrollbar { width: 0 !important; height: 0 !important; }  

* { overflow: -moz-scrollbars-none; }

* { -ms-overflow-style: none; }