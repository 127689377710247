.video-chat-container {
    position: absolute;
    background-color: transparent;
    z-index: 75;
    padding-top: 20px;
    overflow: hidden;
    min-width: 100px;
    min-height:200px ;
}

.streams-scroll {
    overflow: scroll;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;

}

.streamer-window {
    background-color: transparent;
    margin:5px;
    align-items: center;
    flex: none;
    height: fit-content;
    display: flex;
    position: relative;
    flex-direction: column;
}



.streamer-window video {
    position: relative !important;
    flex: 1;
    width: 100%; 
    justify-content: flex-end;
    object-fit: fill !important;
}

.streamer-vid div {
    position: relative;
    width: 100% !important;
    max-height: 100%;
}


.playerhand {
    flex:none;
    position: relative;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    min-width: fit-content;
}


.vert-layout .playerhand {
    width:300px;
    min-width:100%;
}

.vert-layout .streamer-window {
    width:300px;
    margin-bottom:10px;
}

.playerhand .bonecase {
    height: 60px;
    position: relative;
    margin:0 5px;
    width: 25.95px;
}



.streamer-window p {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
}