.card-page {
    position: relative;
    background-color: #2a2c2c;
}

.card-frame {
    position: relative;
    background-color: #2a2c2c;
    padding: 30px;
    padding-bottom: 0px;
    height: 993px;
}
.summary-card-back-img{
    width: 100%;
    top:0px;
    left:0px;
    position: absolute;
}

.players-section {
    position:relative;
    width: 500px;
}

.players-bay p{
    font-size: 20px;
}

.players-section *{
    font-family: "Barlow Semi Condensed";
    font-size: 24px;
}

.scoreback-summary-card .digit-span{
    font-size: 72px;
}

.scoreback-summary-card .scoredigitback{
    height: 100% ;
    width: 100%;
}

.scoreback-summary-card .scorecage{
    height: 100% ;
    width: 100%;
}