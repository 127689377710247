.key-menu-item {
  font-family: rockness;
  color: #2b1700;
  font-size: 48px;
}
.playMenuAudio{
  width: 25px;
  margin-left: 6px;
}
.key-menu-item.unactive {
  opacity: 0.5;
}

.info-collapse * {
  background-color: transparent;
}

.info-collapse p {
  font-family: oatmilk;
}
.info-collapse h1 {
  font-family: oatmilk;
}

.info-collapse p {
  font-size: 24px;
  line-height: 30px;
}

.info-collapse h1 {
  font-size: 36px;
}

.keyImage{
  transform: rotate(50deg) ; 
}

.pieceoutcheck p{
  font-family: oatmilk;
  font-size: 24px;
}

.pieceoutcheck h5{
  font-family: oatmilk;
  font-size: 36px;
}

.pieceoutcheck button{
  font-family: oatmilk;
  font-size: 24px;
}

.pieceoutcheck .modal-body{
  min-height: none;
}

.info-collapse.sub-collapse p{
    margin-bottom: 12px;  
}

.info-collapse.sub-collapse .card-body{
  padding: 0;
}