.dash-board {
    position: relative;
    margin: 0;
    padding: 1px;
    /* background-image: url(/images/homepage.jpeg); */
}

p,
h1 {
    margin: 0;
    text-align: left;

}

.dash-card {
    margin-bottom: 0;
    background-color: transparent;
    background-size: unset 100% ;
}

.third {
    min-height: 33.3%;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}

.game-pill {
    margin: 16px auto;
    background-color: #000;
    padding: 16px 32px;
    border-radius: 16px;
    color: #fff;
    width: 800px;
    animation-name: fadeIn;
    animation-duration: 2s;
}

.game-pill * {

    font-family: "Barlow Semi Condensed";
    font-size: 18px;
}

.barlow {
    font-family: "Barlow Semi Condensed" !important;
}

.newexpbutton{
    height: 150px;
    cursor: pointer;
}

.pill-bay {
    display: flex;
    flex-direction: column;
}

.account_logo{
    height:100px;
}

.history-title{
    color: #2a2c2c;
    text-align: center;
}

.scroll-y{
    overflow: scroll;
}

.dash-drop *{
    font-family: "creamfont";
}