.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "oatmilk";

    /* background-image: url(/images/homepage.jpeg); */
}

.main-page form {
    position: relative;
    border-radius: 16px;
    color:white;
    background-color: transparent !important;

}

.main-page img {
    margin-top: 16px;
}

.main-page form input {
    margin-top: 0;
    margin-bottom: 8px;
    background-color: transparent !important;
    border: 0;
    color: #2a2c2c;
    box-shadow: none;
    border-bottom: 2px dashed #2a2c2c;
}

.main-page form input::placeholder {
                 
    /* Firefox, Chrome, Opera */
    text-align: center;
    color:#2a2c2c;
    font-weight: bold;
}

.main-page form input:-ms-input-placeholder {
     
    /* Internet Explorer 10-11 */
    text-align: center;
    color:#2a2c2c;
    font-weight: bold;
}

.main-page form label {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color:#2a2c2c;
    font-size: 30px;
}

.login-title {
    margin: 32px 0;
    color: #bc8420;
    font-family: "oatmilk";
    font-size: 30px;
    text-transform: uppercase;
    text-shadow: 0 0 1px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 30%);
}

.start-button{
    border:0;
    font-family: "oatmilk";
    color:white;
    padding: 5px 20px;
    background-color: #bc8420;
    font-size: 24px;
    text-transform: uppercase;
}

.page-icon-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: transparent;
    border:0;
    box-shadow: none;
    text-decoration: none;
}

.page-icon-button p{
    font-weight: bold;
    color:#2a2c2c;
    font-size: 24px;
}

.page-icon-img{
    position: relative;
    height: 50px;
}