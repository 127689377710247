.playerhand-b .bonecase {
    height: 40px;
    position: relative;
}
.playerhand-b img {
    vertical-align: unset;
}

.bottomBar {
    transition: bottom .5s ease;
}