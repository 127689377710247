.glyph-name{
    width: 250px;
    flex-shrink:0;
}

.bone-count{
    font-size: 22px;
}

.table-head p{
    font-weight: 700 !important;
    font-size: 22px;
}
.section-title{
    font-size: 22px !important;;
    font-weight: 700 !important;
}


.group-total p{
    font-size: 22px;
    font-weight: 700 !important;
}

.group-count{
    width: 50px;
    margin-left: 12px;
    flex-shrink:0;
    font-size: 20px;
}

.group-title{
    font-size: 20px;
}

.group-points{
    width: 50px;
    margin-left: 12px;
    flex-shrink:0;
    font-size: 20px;
}
.coe-table *{
    font-size: 16px;
}
.table-head .group-count p{
    position: relative;
    left:-70%
}


.table-head  .group-points p{
    position: relative;
    left:-70%
}

.summary-drag div {

    /* border-color: grey !important;
    color: lightgray; */
}

.scoreSummary * {
     color:#2B1700;
     font-family:'smallmem';
     font-weight: 400;
     border-color:#2B1700 !important;
}


 .digit-span{
    font-size:48px;
}


 .scoredigitback{
    height: 42px; 
    width: 21px; 
}

 .scorecage{
    height: 42px; 
    width: 21px; 
}
/* .scoreSummary span {
    color:#2B1700;
    font-family:smallmem;
    font-size: 20px;
 
} */