.setup-body{
    opacity: 0;
    transition: opacity 2s ease-in;
    visibility:hidden;
}

.setupfade{
    opacity: 1 !important;
    visibility: visible !important;
}


.keymessage p{
    font-size: 20px;
    line-height: 1.15;
}