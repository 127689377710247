.score-door-a {
    left: 0;
}

.score-door-b {
    right: 0;
}


@keyframes doora {
    0% {
        left: -0%
    }

    100% {
        left: -100%
    }
}

@keyframes doorb {
    0% {
        right: -0%
    }

    100% {
        right: -100%
    }
}


@keyframes doorac {
    0% {
        left: -100%
    }

    100% {
        left: -0%
    }
}

@keyframes doorbc {
    0% {
        right: -100%
    }

    100% {
        right: -0%
    }
}



.score-door-a.open-door {
    left: -100%;
    animation-name: doora;
    animation-duration: 1s;
}

.score-door-b.open-door {
    right: -100%;
    animation-name: doorb;
    animation-duration: 1s;
}



.score-door-a.close-door {
    left: 0;
    animation-name: doorac;
    animation-duration: 1s;
}

.score-door-b.close-door {
    right: 0;
    animation-name: doorbc;
    animation-duration: 1s;
}