.App {
  text-align: center;
}
/* 
 * {
  cursor: none !important;
} */

iframe{
  z-index: 0 !important;
  height: 0 !important;
  width: 0 !important;
}
.flex-1 {

  flex: 1
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    
  }
}


.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes touchwave {
  0% {
    top: 0px 
   }

   25% {
    top: -5px 
   }


  50% {
    top: -10px 
   }

  75% {
    top: -5px 
   }


  100% {
    top: 0px 
   }
}

.touch-sign{
  position:relative;
  animation-name: touchwave;
  animation-duration:2s;
  animation-iteration-count: infinite;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bottom {
  transform: rotateX(180deg);
}





.play-space {
  position: relative;
  height: 80%;
  width: 80%
}

.hubbar-scroll{
  position: absolute;

  width: 100%;
  display: flex;
  overflow: scroll;
  overflow-x: scroll;
  background-color: transparent;
  z-index: 50;
}


.hubbar-scroll  .nodiv{
  position: relative;
  background-color: transparent;
  border: 1px solid black;
  min-width: 100%;
  flex-shrink: 0;
  opacity: .2;
}

.hubBar {
  top:0;
  left:0;
  position: absolute;
  z-index: 50;
  background-color: transparent;
  padding: 6px;
  padding-bottom: 24px;
  font-weight: bold;
  display: flex;
  min-width: 100%;
  flex-shrink: 0;
  align-items: center;
  /* overflow: scroll; */
  touch-action: none;


}

.blackbt {
  z-index: 50;
  background-color: black;
  padding: 6px;
  font-weight: bold;
  margin: 6px;
  color: gold
}

.wasteCount {
  z-index: 50;
  background-color: gold;
  padding: 18px;
  font-weight: bold;
  margin: 6px;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.faded {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: black;
  opacity: .6;
}

.menu-content {

  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.well-space {
  background-color: lightgrey;
  height: 70%;
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
}

.userList {
  overflow: scroll;
}

.about-row {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 25%;
  left: 0;
  top: 5%;
}


.glyph-spot {
  height: 40%;
}

.well-bone-space {
  height: 25%;
  width: 14.285%;
  padding: 2.5% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  touch-action: none;
  /* background-color: black; */

}



.box-test {
  z-index: 50;


  position: absolute;
  background-color: aqua;
  opacity: .6;
  height: 3.9%;
  width: 4.3%;
}

.score-card {
  position: relative;


  height: 10%;
}



.btn-info,.btn-info:hover,.btn-info:focus {

  background-color: white ;
  color: #bc8420;
  border: 0;
}


.btn-primary,.btn-primary:hover,.btn-primary:focus  {

  background-color: #bc8420; ;
  color: white;
  border: 0;
}



.cohado-alert div{
  border:0
}


.text-danger{
  color:#da7002 !important;
}


.greh{
  color:#676767 !important;
}


.greh-lite{
  color:#8b8b8b !important;
}


