.communityFeedBackPopUp textarea {
     color:#2B1700 !important;
     font-family:'orange_typewriter';
     font-weight: 400;
     font-size: 24px;
     line-height: 24px;
}

.communityFeedBackPopUp  *{
     color:#2B1700 !important;
     font-family:'smallmem';
     font-weight: 400;
     font-size: 24px;
     line-height: 24px;
}