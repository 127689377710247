.cofile input {
    padding: 0;
}

.cofile textarea {
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    font-family: 'orange_typewriter';
}

.cofile label {
    font-size: 24px;
    font-family: 'creamfont';
}

.bigcontainer{
    width: 60%;
}

@media (max-width: 575.98px) {
    .bigcontainer{
        width: 90%;
    }
 }
