
.btn-info,.btn-info:hover,.btn-info:focus {

  background-color: white ;
  color: #bc8420;
  border: 0;
}


.btn-primary,.btn-primary:hover,.btn-primary:focus  {

  background-color: #bc8420; ;
  color: white;
  border: 0;
}
